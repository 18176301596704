<template>
  <div
    id="content"
    class="m-4"
  >
    <RouterButton
      icon="home"
      to="/"
    >
      Home
    </RouterButton>

    <Markdown
      class="markdown mt-4"
      :html="true"
      :source="source"
    />
  </div>
</template>

<script>
import RouterButton from '@/components/RouterButton.vue'

export default {
  components: {
    RouterButton,
  },

  props: {
    file: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      source: '',
    }
  },

  async created() {
    this.source  = await import('@/assets/markdown/' + this.file).then((m) => m.default)
  },
}
</script>

<style>
#content h2 {
  text-decoration: underline;
}

#content h2 + h3 {
  margin-top: 25px;
}

#content .markdown {
  font-family: monospace;
}
</style>
